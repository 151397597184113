html {
  font-size: 100%;
  /* background-color: #f8fafc !important; */
}

/* @font-face {
  font-family: "Rubik";
  src: url(Rubik-Bold.ttf);
} */

body {
  font-family: muli, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  /* Margin 0 is important otherwise the elements start behaving by adding margin */
}

.MuiAccordionSummary-root {
  padding: "0px 8px"
}

/*Test */
.MuiCardContent-root:last-child {
  padding-bottom: 24px;
  padding: 24px;
}

.slick-slide iframe {
  margin: auto;
}

.active {
  padding: 16px;
  border-bottom: 6px solid #0F7DC2;
  pointer-events: none;
}

@media only screen and (min-width: 1600px) {
  body {
    width: 1440px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 950px) {
  .active {
    font-weight: bold !important;
    color: #0F7DC2 !important;
    font-size: 100% !important;
    border-bottom: 0px;
  }

  .activeDropdown {
    font-weight: bold !important;
    color: #0F7DC2 !important;
  }
}